
import React, { useState, useEffect } from "react";
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./NavBar.css";

export default function CustomNavbar() {
  const navigate = useNavigate();
  const location = useLocation(); // Get the current path
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsLoggedIn(!!token);
  }, [location.pathname]); // Update state on route change

  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsLoggedIn(false);
    navigate("/"); // Redirect to home after logout
  };

  const handleDropdownToggle = (isOpen) => {
    setShowDropdown(isOpen);
  };

  return (
    <Navbar
      bg="black"
      expand="lg"
      className="justify-content-between navbar-dark"
    >
      <Container>
        {/* Explore Button */}
        {location.pathname !== "/feed" && !location.pathname.startsWith("/preview/") &&(
          <Nav className="me-auto">
            <a
              href="/feed"
              className="btn btn-dark btn-lg custom-signup-button"
            >
              Explore
              <div className="custom-chevron"></div>
            </a>
          </Nav>
        )}

        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <MenuIcon style={{ color: "white" }} />
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto fs-2">
            <Link to="/">
              <strong style={{ color: "white", textDecoration: "none" }}>
                style-sync
              </strong>
            </Link>
          </Nav>

          <Nav className="ms-auto justify-content-end">
            {/* Sign In and Sign Up buttons for non-signed-in users */}
            {!isLoggedIn && (
              <>
                <a
                  href="/signin"
                  className="btn btn-dark btn-lg custom-signin-button mr-3"
                >
                  Sign in
                </a>
                <a
                  href="/signup"
                  className="btn btn-outline-light btn-lg custom-signup-button"
                >
                  Sign up
                </a>
              </>
            )}

            {/* Logout button for signed-in users */}
            {isLoggedIn && (
              <button
                className="btn btn-dark btn-lg custom-logout-button"
                onClick={handleLogout}
              >
                Log out
              </button>
            )}

            {/* Hamburger menu dropdown for signed-in users */}
            {isLoggedIn && (
              <NavDropdown
                title={<MenuIcon style={{ color: "white" }} />}
                id="basic-nav-dropdown"
                show={showDropdown}
                onToggle={handleDropdownToggle}
              >
                <NavDropdown.Item href="/profile">Profile</NavDropdown.Item>
                <NavDropdown.Item href="/favorites">
                  Favorite List
                </NavDropdown.Item>
              </NavDropdown>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
