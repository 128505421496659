import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./setCard.css";

const SetCard = ({
  set,
  mode,
  setCategory,
  topItemUrl,
  bottomItemUrl,
  shoesItemUrl,
}) => {
  const [liked, setLiked] = useState(false);
  const [added, setAdded] = useState(false);
  const [voteCount, setVoteCount] = useState(0);
  const [successMessage, setSuccessMessage] = useState(""); 
  const [errorMessage, setErrorMessage] = useState(""); 

  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("token");

  const navigate = useNavigate();

  const clearMessageAfterTimeout = (setMessage) => { 
    setTimeout(() => {
      setMessage("");
    }, 2000); 
  };
  useEffect(() => {
    // Fetch initial vote count
    if (mode !== "mixAndMatch") {
      fetch(`${API_URL}/set/${set.setId}/vote`)
        .then((res) => {
          if (!res.ok) throw new Error("Failed to fetch vote count");
          return res.json();
        })
        .then((data) => {
          setVoteCount(data.voteVal || 0);
        })
        .catch((error) => {
          console.error("Error fetching vote count:", error);
        });

      // Check if the set is already added to favorites
      fetch(`${API_URL}/getUser`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.favorite && data.favorite.includes(set.setId)) {
            setAdded(true);
          }
        })
        .catch((error) =>
          console.error("Error checking favorite status:", error)
        );
    }
  }, [API_URL, token]);

  const handleLike = async (e) => {
    e.stopPropagation(); 
    if (!token) {
      setErrorMessage("Please log in to vote."); 
      clearMessageAfterTimeout(setErrorMessage); 
      return;
    }

    const voteValue = liked ? 0 : 1;

    fetch(`${API_URL}/vote/${set.setId}`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ value: voteValue }),
    })
      .then((response) => {
        if (!response.ok) throw new Error("Failed to update vote");
        return response.json();
      })
      .then(() => {
        setLiked(!liked);
        setVoteCount((prevCount) => prevCount + (liked ? -1 : 1));
      })
      .catch((error) => {
        console.error("Error updating vote:", error);
      });
  }

  function handleAdd(e) {
    e.stopPropagation(); 

    if (!token) {
      setErrorMessage("Please log in to manage favorites."); 
      clearMessageAfterTimeout(setErrorMessage);
      return;
    }

    if (added) {
      // Remove from favorites
      fetch(`${API_URL}/unfavorite/${set.setId}`, {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          if (!res.ok) throw new Error("Failed to remove from favorites");
          return res.json();
        })
        .then((data) => {
          if (data.message === "Removed from favorites") {
            setAdded(false);
            setSuccessMessage("Set removed from favorites!"); 
        clearMessageAfterTimeout(setSuccessMessage); 
          }
        })
        .catch((error) => console.error("Error removing favorite:", error));
    } else {
      // Add to favorites
      fetch(`${API_URL}/setFavorite/${set.setId}`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          if (!res.ok) throw new Error("Failed to add to favorites");
          return res.json();
        })
        .then((data) => {
          if (data.message === "Set added to favorites!") {
            setAdded(true);
            setSuccessMessage("Set added to favorites!"); 
            clearMessageAfterTimeout(setSuccessMessage); 
          }
        })
        .catch((error) => {
          setErrorMessage("Error updating favorites."); 
          clearMessageAfterTimeout(setErrorMessage); 
        });
    }
  }

  function handleEditClick(e) {
    e.stopPropagation(); 
    navigate(`/mix-and-match/${set.setId}`, { state: { set } });
  }

  function handleCardClick() {
    navigate(`/preview/${set.setId}`, { state: { set } });
  }

  if (mode === "feed") {
    return (
      <div className="set-card">
      
        {successMessage && <div className="success-message">{successMessage}</div>} 
        {errorMessage && <div className="error-message">{errorMessage}</div>} 

        <div
          className="set-card-desc-container d-flex align-items-end"
          onClick={handleCardClick} 
        >
          <div className="set-card-desc container-fluid d-flex">
            <div className="set-card-nameAndMadeBy-container align-self-end col-10 d-flex flex-column container-fluid">
              <div className="set-card-name-container row col">
                <h3 className="set-card-name">Set Name</h3>
              </div>
              <div className="set-card-madeBy-container row col align-items-end">
                <p className="set-card-madeBy">Made by @Alisedighmoghadam</p>
         
              </div>
            </div>

            <div className="set-card-icons-container container-fluid d-flex flex-column col-2 align-self-end">
              <div className="set-card-fav-button-container align-items-end row col">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="-1.2 -2.4 26.4 24.5"
                  fill="none"
                  x="0px"
                  y="0px"
                  className="set-card-fav-button"
                  onClick={handleLike} 
                >
                  <path
                    d="M23 8.71006C23 13.3501 17.2 18.2201 13.75 20.7101C13.24 21.0763 12.6279 21.2734 12 21.2734C11.3721 21.2734 10.76 21.0763 10.25 20.7101C6.8 18.2201 1 13.3501 1 8.71006C1.00142 7.42169 1.41751 6.16799 2.18672 5.13444C2.95593 4.1009 4.03739 3.3424 5.27112 2.97116C6.50484 2.59992 7.8253 2.63566 9.03714 3.07309C10.249 3.51052 11.2878 4.32641 12 5.40006C12.7122 4.32641 13.751 3.51052 14.9629 3.07309C16.1747 2.63566 17.4952 2.59992 18.7289 2.97116C19.9626 3.3424 21.0441 4.1009 21.8133 5.13444C22.5825 6.16799 22.9986 7.42169 23 8.71006V8.71006Z"
                    fill={liked ? "red" : "white"}
                  />
                </svg>
                <p className="set-card-vote-count text-center mt-1">
                  {voteCount}
                </p>{" "}
                {/* Vote count displayed below the heart */}
              </div>

              <div className="set-card-edit-button-container align-items-end row col">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="-1.2 -2.4 26.4 32.4"
                  fill="none"
                  x="0px"
                  y="0px"
                  className="set-card-edit-button"
                  onClick={handleEditClick} 
                >
                  <path
                    d="M22 21H2C1.73478 21 1.48043 21.1054 1.29289 21.2929C1.10536 21.4804 1 21.7348 1 22C1 22.2652 1.10536 22.5196 1.29289 22.7071C1.48043 22.8946 1.73478 23 2 23H22C22.2652 23 22.5196 22.8946 22.7071 22.7071C22.8946 22.5196 23 22.2652 23 22C23 21.7348 22.8946 21.4804 22.7071 21.2929C22.5196 21.1054 22.2652 21 22 21Z"
                    fill="white"
                  />
                  <path
                    d="M4.00002 19H8.24002C8.37994 19.0076 8.51991 18.9858 8.65084 18.9358C8.78176 18.8858 8.90073 18.8089 9.00002 18.71L10.62 17L4.93002 11.41L3.29002 13.05C3.19734 13.1435 3.12401 13.2543 3.07425 13.3761C3.02448 13.498 2.99926 13.6284 3.00002 13.76V18C3.00002 18.2653 3.10537 18.5196 3.29291 18.7071C3.48045 18.8947 3.7348 19 4.00002 19Z"
                    fill="white"
                  />
                  <path
                    d="M12.0301 15.63L17.6201 10.03L11.9401 4.41003L6.3501 9.99003L12.0301 15.63Z"
                    fill="white"
                  />
                  <path
                    d="M19.0001 8.62003L20.1101 7.51003C20.6719 6.94753 20.9875 6.18503 20.9875 5.39003C20.9875 4.59502 20.6719 3.83253 20.1101 3.27003L18.7301 1.86003C18.1676 1.29822 17.4051 0.982666 16.6101 0.982666C15.8151 0.982666 15.0526 1.29822 14.4901 1.86003L13.3501 3.00003L19.0001 8.62003Z"
                    fill="white"
                  />
                </svg>
              </div>

              <div className="set-card-add-button-container align-items-end row col">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  width="55px"
                  height="55px"
                  x="0px"
                  y="0px"
                  className="set-card-add-button"
                  onClick={handleAdd} 
                >
                  <circle cx="12" cy="12" r="10" fill="white" />{" "}
                  {/* Background circle */}
                  <path
                    d={added ? "M8 12h8" : "M12 7v10M7 12h10"} 
                    stroke="#0d0d0d"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div className="set-card-top-image">
          <img src={set.items.topImage} alt="Top Item" />
        </div>
        <div className="set-card-bottom-image">
          <img src={set.items.bottomImage} alt="Bottom Item" />
        </div>
        <div className="set-card-shoes-image">
          <img src={set.items.shoesImage} alt="Shoes" />
        </div>
      </div>
    );
  } else if (mode === "preview") {
    return (
      <div className="set-card-preview container-fluid d-flex row">
        <div className="set-card-preview-icon-container col-2 ">
          <div className="set-card-preview-icons-container container-fluid d-flex flex-column ">
            <div className="set-card-fav-button-container p-1 row col-2 mb-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="-1.2 -2.4 26.4 32.4"
                fill="none"
                x="0px"
                y="0px"
                className="set-card-fav-button"
                onClick={handleLike} 
              >
                <path
                  d="M23 8.71006C23 13.3501 17.2 18.2201 13.75 20.7101C13.24 21.0763 12.6279 21.2734 12 21.2734C11.3721 21.2734 10.76 21.0763 10.25 20.7101C6.8 18.2201 1 13.3501 1 8.71006C1.00142 7.42169 1.41751 6.16799 2.18672 5.13444C2.95593 4.1009 4.03739 3.3424 5.27112 2.97116C6.50484 2.59992 7.8253 2.63566 9.03714 3.07309C10.249 3.51052 11.2878 4.32641 12 5.40006C12.7122 4.32641 13.751 3.51052 14.9629 3.07309C16.1747 2.63566 17.4952 2.59992 18.7289 2.97116C19.9626 3.3424 21.0441 4.1009 21.8133 5.13444C22.5825 6.16799 22.9986 7.42169 23 8.71006V8.71006Z"
                  fill={liked ? "red" : "white"}
                />
              </svg>
            </div>

            <div className="set-card-edit-button-container p-1 row col-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="-1.2 -2.4 26.4 32.4"
                fill="none"
                x="0px"
                y="0px"
                className="set-card-edit-button"
                onClick={handleEditClick} 
              >
                <path
                  d="M22 21H2C1.73478 21 1.48043 21.1054 1.29289 21.2929C1.10536 21.4804 1 21.7348 1 22C1 22.2652 1.10536 22.5196 1.29289 22.7071C1.48043 22.8946 1.73478 23 2 23H22C22.2652 23 22.5196 22.8946 22.7071 22.7071C22.8946 22.5196 23 22.2652 23 22C23 21.7348 22.8946 21.4804 22.7071 21.2929C22.5196 21.1054 22.2652 21 22 21Z"
                  fill="white"
                />
                <path
                  d="M4.00002 19H8.24002C8.37994 19.0076 8.51991 18.9858 8.65084 18.9358C8.78176 18.8858 8.90073 18.8089 9.00002 18.71L10.62 17L4.93002 11.41L3.29002 13.05C3.19734 13.1435 3.12401 13.2543 3.07425 13.3761C3.02448 13.498 2.99926 13.6284 3.00002 13.76V18C3.00002 18.2653 3.10537 18.5196 3.29291 18.7071C3.48045 18.8947 3.7348 19 4.00002 19Z"
                  fill="white"
                />
                <path
                  d="M12.0301 15.63L17.6201 10.03L11.9401 4.41003L6.3501 9.99003L12.0301 15.63Z"
                  fill="white"
                />
                <path
                  d="M19.0001 8.62003L20.1101 7.51003C20.6719 6.94753 20.9875 6.18503 20.9875 5.39003C20.9875 4.59502 20.6719 3.83253 20.1101 3.27003L18.7301 1.86003C18.1676 1.29822 17.4051 0.982666 16.6101 0.982666C15.8151 0.982666 15.0526 1.29822 14.4901 1.86003L13.3501 3.00003L19.0001 8.62003Z"
                  fill="white"
                />
              </svg>
            </div>
            <div className="flex-grow-1"></div>
            <div className="set-card-add-button-container p-1 row col-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                width="55px"
                height="55px"
                x="0px"
                y="0px"
                className="set-card-add-button"
                onClick={handleAdd} 
              >
                <circle cx="12" cy="12" r="10" fill="white" />{" "}
                {/* Background circle */}
                <path
                  d={added ? "M8 12h8" : "M12 7v10M7 12h10"} 
                  stroke="#0d0d0d"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className="set-card-preview-image-container col">
          <a href={topItemUrl} target="_blank">
            <div className="set-card-top-image">
              <img src={set.items.topImage} alt="Top Item" />
            </div>
          </a>
          <a href={bottomItemUrl} target="_blank">
            <div className="set-card-bottom-image">
              <img src={set.items.bottomImage} alt="Bottom Item" />
            </div>
          </a>
          <a href={shoesItemUrl} target="_blank">
            <div className="set-card-shoes-image">
              <img src={set.items.shoesImage} alt="Shoes" />
            </div>
          </a>
        </div>
      </div>
    );
  } else if (mode === "mixAndMatch") {
    return (
      <div className="set-card-mix-and-match container-fluid d-flex row">
        <div className="set-card-mix-and-match-image-container col">
          <div
            className="set-card-top-image set-card-top-image-mix-and-match"
            onClick={() => setCategory("top")}
          >
            <img src={set.items.topImage} alt="Top Item" />
          </div>
          <div
            className="set-card-bottom-image set-card-bottom-image-mix-and-match"
            onClick={() => setCategory("bottom")}
          >
            <img src={set.items.bottomImage} alt="Bottom Item" />
          </div>
          <div
            className="set-card-shoes-image set-card-shoes-image-mix-and-match"
            onClick={() => setCategory("shoes")}
          >
            <img
              src={set.items.shoesImage}
              alt="Shoes"
              style={
                set.setId
                  ? { objectPosition: "0 90%" }
                  : { objectPosition: "0" }
              }
            />
          </div>
        </div>
      </div>
    );
  }
};

export default SetCard;
