import React, { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom"; // Added `useNavigate`
import SetCard from "../SetCard";
import "./css/mixAndMatch.css";
import Top from "./assets/top.png";
import Bottom from "./assets/bottom.png";
import Shoes from "./assets/shoes.png";
import ItemsCard from "../itemsCard";
import classNames from "classnames";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const MixAndMatch = () => {
  const { state } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate(); // Added `navigate`
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("token");
  const [set, setSet] = useState(
    state?.set || {
      setId: id,
      items: {
        topImage: Top,
        topId: null,
        bottomImage: Bottom,
        bottomId: null,
        shoesImage: Shoes,
        shoesId: null,
      },
    }
  );
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [category, setCategory] = useState("top");
  const [changedItem, setChangedItem] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState("");

  const handleNameChange = (e) => {
    const value = e.target.value;
        // Basic security validation: ensure name contains only letters and spaces
    if (/^[a-zA-Z\s]*$/.test(value)) {
      setName(value);
      setError("");
    } else {
      setError("Name can only contain letters and spaces");
    }
  };

  const handleSubmit = (e) => {
    const submitValue = {
      topId: set.items.topId,
      bottomId: set.items.bottomId,
      shoesId: set.items.shoesId,
    };
    console.log(submitValue);
    e.preventDefault();
    setValidated(true);
    if (name && description && !error) {
      fetch(`${API_URL}/set`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ items: submitValue }),
      })
        .then((res) => {
          if (!res.ok) throw new Error("Failed to submit the set");
          return res.json();
        })
        .catch((error) => {
          console.log("Error creating set:", error);
        });
    }
  };

  useEffect(() => {
    if (changedItem !== null) {
      if (category === "top") {
        setSet({
          setId: id,
          items: {
            topImage: changedItem.itemImage,
            topId: changedItem.itemId,
            bottomImage: set.items.bottomImage,
            bottomId: set.items.bottomId,
            shoesImage: set.items.shoesImage,
            shoesId: set.items.shoesId,
          },
        });
      } else if (category === "bottom") {
        setSet({
          setId: id,
          items: {
            topImage: set.items.topImage,
            topId: set.items.topId,
            bottomImage: changedItem.itemImage,
            bottomId: changedItem.itemId,
            shoesImage: set.items.shoesImage,
            shoesId: set.items.shoesId,
          },
        });
      } else if (category === "shoes") {
        setSet({
          setId: id,
          items: {
            topImage: set.items.topImage,
            topId: set.items.topId,
            bottomImage: set.items.bottomImage,
            bottomId: set.items.bottomId,
            shoesImage: changedItem.itemImage,
            shoesId: changedItem.itemId,
          },
        });
      }
    }
    console.log(changedItem);
  }, [changedItem]);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (windowDimensions.width > 700) {
    return (
      <div className="mix-and-match container-fluid d-flex">
        {/* Updated part: Adding back button */}
        <button
          className="btn btn-secondary back-button"
      
          onClick={() => navigate(-1, { state: { set } })} // Redirect to Previous page
          style={{
            position: "absolute",
            top: "70px",
            left: "10px",
            zIndex: 1000,
            backgroundColor: "#6c757d",
            color: "white",
            border: "none",
            padding: "6px 12px",
            borderRadius: "4px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            cursor: "pointer",
            fontSize: "14px",
          }}
        >
          <i className="fas fa-arrow-left"></i>
        </button>
        {/* End of updated part */}

        <SetCard
          set={set}
          mode={"mixAndMatch"}
          setCategory={setCategory}
          className="col"
        />
        <ItemsCard
          className="col"
          category={category}
          setChangedItem={setChangedItem}
        />
        <div className="mix-and-match-form-container col">
          <form noValidate onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="formName">Name</label>
              <input
                type="text"
                id="formName"
                placeholder="Enter sets name"
                value={name}
                onChange={handleNameChange}
                className={classNames("form-control", {
                  "is-invalid": validated && error,
                  "is-valid": validated && !error && name,
                })}
              />
              {error && <div className="invalid-feedback">{error}</div>}
            </div>

            <div className="form-group mt-3">
              <label htmlFor="formDescription">Description</label>
              <textarea
                id="formDescription"
                rows="3"
                placeholder="Enter a description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className={classNames("form-control", {
                  "is-invalid": validated && !description,
                  "is-valid": validated && description,
                })}
              />
              <div className="invalid-feedback">
                Please provide a description.
              </div>
            </div>

            <button type="submit" className="btn btn-primary mt-3">
              Publish
            </button>
          </form>
        </div>
      </div>
    );
  }
};

export default MixAndMatch;