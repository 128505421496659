import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css"; // Import your App.css file
import Profile from "./components/Profile";
import FavoriteList from "./components/FavoriteList";
import Navbar from "./components/NavBar.js";
import Home from "./pages/Home/Home";
import SignUp from "./components/SignUp.js";
import SignIn from "./components/SignIn.js";
import ResetPassword from "./components/ResetPassword";
import Product from "./components/Product";
import Feed from "./components/feedPage/Feed.jsx";
import PreviewPage from "./components/preview/Preview.jsx"; // Importing the PreviewPage
import MixAndMatch from "./components/mixAndMatchPage/MixAndMatch.jsx";
import '@fortawesome/fontawesome-free/css/all.min.css';

function App() {
  const handleLogout = () => {
    // Perform logout actions here
  };

  return (
    <Router>
      <div className="">
        <Navbar />
        <Routes>
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/" element={<Home />} />
          <Route path="/forgot-password" element={<ResetPassword />} />
          <Route path="/product" element={<Product />} />
          <Route path="/preview/:setId" element={<PreviewPage />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/favorites" element={<FavoriteList />} />
          {/* <Route path="/mix-and-match/:id" element={<MixAndMatchNewPage />} /> */}
          <Route
            path="/logout"
            element={<button onClick={handleLogout}>Logout</button>}
          />
          <Route path="/feed" element={<Feed />} />
          <Route path="/mix-and-match/:id" element={<MixAndMatch />} />
          <Route path="/mix-and-match" element={<MixAndMatch />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
