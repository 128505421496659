import React, { useEffect, useState } from "react";
import "./individualItemCard.css";

const IndividualItemCard = ({ item, display, setRequestedItem }) => {
  function handleClick() {
    setRequestedItem({ itemId: item.itemId, itemImage: item.image });
  }
  function handleLinkClick(event) {
    event.stopPropagation(); // Prevent the event from bubbling up
  }
  return (
    <div
      className="individual-item-card  "
      style={display ? { display: "block" } : { display: "none" }}
      onClick={handleClick}
    >
      <div className="individual-item-card-desc-container container d-flex flex-column ">
        <h5 className="individual-item-card-desc-name mt-auto ">{item.name}</h5>
        <h6 className="individual-item-card-desc-brand ">Gap</h6>
        <a
          href={item.url}
          className="individual-item-card-desc-url align-self-end"
          target="_blank"
          onClick={handleLinkClick}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Layer 1"
            viewBox="0 0 100 125"
            x="0px"
            y="0px"
          >
            <title>Artboard 74</title>
            <path d="M66,71H46v6H66A11,11,0,0,0,77,66V34A11,11,0,0,0,66,23H34A11,11,0,0,0,23,34V64.34a7,7,0,0,0,12,5L55,49.24V61h6V42a3,3,0,0,0-3-3H39v6H50.76l-20,20.05A1,1,0,0,1,29,64.34V34a5,5,0,0,1,5-5H66a5,5,0,0,1,5,5V66A5,5,0,0,1,66,71Z" />
          </svg>
        </a>
      </div>
      <img className="individual-item-card-image" src={item.image} alt="" />
    </div>
  );
};

export default IndividualItemCard;
