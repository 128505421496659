import React, { useEffect, useState } from "react";
import "./itemsCard.css";
import IndividualItemCard from "./individualItemCard";

const ItemsCard = ({ category, setChangedItem }) => {
  const [items, setItems] = useState([]);
  const [itemType, setItemType] = useState(category);
  const API_URL = process.env.REACT_APP_API_URL;
  const [requestedItem, setRequestedItem] = useState(null);
  const [gender, setGender] = useState("All");
  useEffect(() => {
    if (requestedItem !== null) {
      setChangedItem(requestedItem);
    }
  }, [requestedItem]);

  useEffect(() => {
    fetch(`${API_URL}/items`)
      .then((res) => {
        if (!res.ok) {
          throw new Error("No response");
        }
        return res.json();
      })
      .then((data) => {
        setItems(data);
      })
      .catch((error) => {
        console.log("Error fetching items:", error);
      });
  }, [API_URL]);
  useEffect(() => {
    setItemType(category);
  }, [category]);

  return (
    <div className="items-card container-fluid row d-flex align-content-lg-stretch">
      <div className="items-card-gender-filter row justify-content-center">
        <button
          className={`col-sm-2 items-card-gender-option btn  mt-1 ${
            gender === "All" ? "btn-primary" : "btn-secondary"
          }`}
          onClick={() => setGender("All")}
        >
          All
        </button>
        <button
          className={`col-sm-2 items-card-gender-option btn  mt-1 ${
            gender === "Men" ? "btn-primary" : "btn-secondary"
          }`}
          onClick={() => setGender("Men")}
        >
          Men
        </button>
        <button
          className={`col-sm-2 items-card-gender-option btn  mt-1 ${
            gender === "Women" ? "btn-primary" : "btn-secondary"
          }`}
          onClick={() => setGender("Women")}
        >
          Women
        </button>
      </div>

      {items.map((item) => {
        return (
          <IndividualItemCard
            key={item.itemId}
            item={item}
            display={
              item.category === itemType &&
              (item.gender === gender || gender === "All")
            }
            setRequestedItem={setRequestedItem}
          />
        );
      })}
    </div>
  );
};

export default ItemsCard;
