import React, { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import SetCard from "../SetCard";
import "./css/Preview.css";

const PreviewPage = () => {
  const { state } = useLocation();
  const [set, setSet] = useState(state?.set || null);
  const { id } = useParams();
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [topItemUrl, setTopItemUrl] = useState();
  const [bottomItemUrl, setBottomItemUrl] = useState();
  const [shoesItemUrl, setShoesItemUrl] = useState();

  useEffect(() => {
    if (!set) {
      fetch(`${API_URL}/set/${id}`)
        .then((res) => {
          if (!res.ok) throw new Error("Network response was not ok");
          return res.json();
        })
        .then((data) => {
          setSet(data);
        })
        .catch((error) => {
          console.error("Error fetching set data:", error);
        });
    }
  }, [API_URL, id, set]);

  useEffect(() => {
    fetch(`${API_URL}/item/${set.items.topId}`)
      .then((res) => {
        if (!res.ok) throw new Error("Failed to fetch top url");
        return res.json();
      })
      .then((data) => {
        setTopItemUrl(data.url);
      })
      .catch((error) => {
        console.error("Error fetching item url:", error);
      });
    fetch(`${API_URL}/item/${set.items.bottomId}`)
      .then((res) => {
        if (!res.ok) throw new Error("Failed to fetch bottom url");
        return res.json();
      })
      .then((data) => {
        setBottomItemUrl(data.url);
      })
      .catch((error) => {
        console.error("Error fetching item url:", error);
      });
    fetch(`${API_URL}/item/${set.items.shoesId}`)
      .then((res) => {
        if (!res.ok) throw new Error("Failed to fetch shoes url");
        return res.json();
      })
      .then((data) => {
        setShoesItemUrl(data.url);
      })
      .catch((error) => {
        console.error("Error fetching item url:", error);
      });
  }, [set]);

  function handleShopClick() {
    setTimeout(() => window.open(topItemUrl, "_blank"), 100);
    setTimeout(() => window.open(bottomItemUrl, "_blank"), 200);
    setTimeout(() => window.open(shoesItemUrl, "_blank"), 300);
  }

  if (!set) {
    return <div>Loading...</div>;
  }

  return (
    <div className="preview-page container">
      {/* Back Button */}
      <button
        className="btn btn-secondary back-button"

        onClick={() => navigate(-1)} // Go back to the previous page
        
        // onClick={() => navigate("/feed")} // Navigate to Feed page

        style={{
          position: "absolute",
          top: "70px",
          left: "10px",
          zIndex: 1000,
          backgroundColor: "#6c757d",
          color: "white",
          border: "none",
          padding: "6px 12px",
          borderRadius: "4px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          cursor: "pointer",
          fontSize: "14px",
        }}
      >
        <i className="fas fa-arrow-left"></i>
      </button>

      <div className="d-flex row justify-content-center">
        <SetCard
          set={set}
          mode={"preview"}
          className="preview-page-set-card col"
          topItemUrl={topItemUrl}
          bottomItemUrl={bottomItemUrl}
          shoesItemUrl={shoesItemUrl}
        />
        <div className="preview-page-desc col">
          <h4>Created by @</h4>
          <h1>Set Name</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum
            molestie nibh eget est accumsan ultricies. Ut nisi leo, sodales at
            metus sed, pretium volutpat sem. Vestibulum ante ipsum primis in
            faucibus orci luctus et ultrices posuere cubilia curae; Cras ut
            ullamcorper purus. Mauris faucibus ante sed turpis dignissim cursus
            quis eget lorem. Sed vitae diam id quam venenatis ullamcorper nec at
            tellus. Duis a diam ut sapien volutpat tristique. Aliquam a lacus
            vel ligula maximus faucibus ut sed tellus. Morbi sit amet lobortis
            nunc, dapibus malesuada risus. Suspendisse commodo risus varius
            semper sagittis. Ut id pellentesque lacus. Cras pulvinar malesuada
            urna id ullamcorper. Quisque odio orci, posuere vitae vestibulum
            non, viverra vel nunc. Duis cursus nibh a sapien volutpat pretium.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
          <button className="btn btn-primary mt-3" onClick={handleShopClick}>
            Shop all
          </button>
        </div>
      </div>
    </div>
  );
};

export default PreviewPage;
