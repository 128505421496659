
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Product.css";

export default function FavoriteList() {
  const [favorites, setFavorites] = useState([]); 
  const [loading, setLoading] = useState(true); 
  const [successMessage, setSuccessMessage] = useState(""); 
  const [errorMessage, setErrorMessage] = useState(""); 
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;

  const clearMessageAfterTimeout = (setMessage) => { 
    setTimeout(() => {
      setMessage("");
    }, 2000); 
  };

  useEffect(() => {
    const fetchFavorites = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          setErrorMessage("No token found. Please log in."); 
          clearMessageAfterTimeout(setErrorMessage); 
          return;
        }

        const userResponse = await fetch(`${API_URL}/getUser`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (userResponse.ok) {
          const userData = await userResponse.json();
          const favoriteSetIds = userData.favorite || [];

          const setsResponse = await fetch(`${API_URL}/sets`);
          const setsData = await setsResponse.json();
          const setsMap = setsData.sets.reduce((map, set) => {
            map[set.setId] = set; 
            return map;
          }, {});

          const favoriteDetails = await Promise.all(
            favoriteSetIds.map(async (setId) => {
              const setResponse = await fetch(`${API_URL}/set/${setId}`);
              if (setResponse.ok) {
                const [setData] = await setResponse.json(); 
                const setWithImages = setsMap[setData.setId] || {}; 
                return {
                  ...setData,
                  items: {
                    ...setData.items,
                    topImage: setWithImages.items?.topImage || null,
                    bottomImage: setWithImages.items?.bottomImage || null,
                    shoesImage: setWithImages.items?.shoesImage || null,
                  },
                };
              } else {
                console.error(`Failed to fetch set details for ID: ${setId}`);
                return null;
              }
            })
          );

          setFavorites(favoriteDetails.filter((set) => set !== null));
        } else {
          setErrorMessage("Failed to fetch user data from /getUser."); 
          clearMessageAfterTimeout(setErrorMessage); 
        }
      } catch (error) {
        setErrorMessage("Error fetching favorites."); 
        clearMessageAfterTimeout(setErrorMessage); 
      } finally {
        setLoading(false); 
      }
    };

    fetchFavorites();
  }, [API_URL]);

  const removeFromFavorites = async (setId) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${API_URL}/unfavorite/${setId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        setFavorites((prevFavorites) =>
          prevFavorites.filter((item) => item.setId !== setId)
        );
        setSuccessMessage("Set removed from favorites!"); 
        clearMessageAfterTimeout(setSuccessMessage); 
      } else {
        setErrorMessage("Failed to remove set from favorites."); 
        clearMessageAfterTimeout(setErrorMessage); 
      }
    } catch (error) {
      setErrorMessage("Error removing favorite set."); 
      clearMessageAfterTimeout(setErrorMessage); 
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="favorite-list-container">

      {successMessage && <div className="success-message">{successMessage}</div>} 
      {errorMessage && <div className="error-message">{errorMessage}</div>} 

      <div className="container">
        <div className="row">
          {favorites.length === 0 ? (
            <p>No favorite items yet.</p>
          ) : (
            favorites.map((set) => (
              <div className="col-lg-3 col-md-4 col-sm-6 mb-4" key={set.setId}>
                <div className="product-card">
                  <h4>{set.name || "Set Name"}</h4>
                  <div className="product-image-list">
                    {set.items?.topImage ? (
                      <img
                        src={set.items.topImage}
                        alt="Top"
                        className="product-img"
                      />
                    ) : (
                      <p>Top image not available</p>
                    )}
                    {set.items?.bottomImage ? (
                      <img
                        src={set.items.bottomImage}
                        alt="Bottom"
                        className="product-img"
                      />
                    ) : (
                      <p>Bottom image not available</p>
                    )}
                    {set.items?.shoesImage ? (
                      <img
                        src={set.items.shoesImage}
                        alt="Shoes"
                        className="product-img"
                      />
                    ) : (
                      <p>Shoes image not available</p>
                    )}
                  </div>
                  <div className="card-content">
                    <div className="button-group">
                      <button
                        className="button"
                        onClick={() =>
                          navigate(`/preview/${set.setId}`, { state: { set } })
                        }
                      >
                        View
                      </button>
                      <button
                        className="button button-delete"
                        onClick={() => removeFromFavorites(set.setId)}
                      >
                        Delete from Favorites
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}
